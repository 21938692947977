export const SENIOR_AGE = 60
export const DAY_TIME_MILLISEC = 24 * 60 * 60 * 1000

export const PLATFORM_TARRAKKI = 'TARRAKKI'
export const PLATFORM_SETU = 'SETU'
export const PLATFORM_UPSWING = 'UPSWING'
export const PLATFORM_FINCARE = 'FINCARE'
export const PLATFORM_AXIS = 'AXIS'

export const ISSUER_FIRSTNAME_BAJAJ = 'Bajaj'
export const ISSUER_FIRSTNAME_SHRIRAM = 'Shriram'
export const ISSUER_FIRSTNAME_MAHINDRA = 'Mahindra'
export const ISSUER_FIRSTNAME_UNITY = 'Unity'

export type Platform =
	| typeof PLATFORM_TARRAKKI
	| typeof PLATFORM_SETU
	| typeof PLATFORM_UPSWING
	| typeof PLATFORM_FINCARE
	| typeof PLATFORM_AXIS

export const TARRAKKI_STATUS_NEW = 'new'
export const TARRAKKI_STATUS_AWAITING_PAYMENT = 'awaiting_payment'
export const TARRAKKI_STATUS_AWAITING_DOCUMENTS = 'awaiting_documents'
export const TARRAKKI_STATUS_UNDER_REVIEW = 'under_review'
export const TARRAKKI_STATUS_ACTIVE = 'active'
export const TARRAKKI_STATUS_MATURED = 'matured'
export const TARRAKKI_STATUS_REJECTED = 'rejected'
